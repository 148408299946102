// ** MUI import
import Box from "@mui/material/Box";

// ** Assets import
import { AHAssets } from "../../assets";

const Hero2Slice = () => {
  return (
    <Box className="ah-hero-slice-container">
      <img src={AHAssets.AHHero2} className="hero2-slice-img" />
    </Box>
  );
};

export default Hero2Slice;
