// ** MUI Import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Swiper components import
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

// ** Swiper css import
import "swiper/css";

// Components import
import {
  AHGamePlay1,
  AHGamePlay2,
  AHGamePlay3,
  AHGamePlay4,
} from "../../components/game-plays";

import "./styles.css";

const AHGamePlaySection = () => {
  return (
    <Box id="ah-game-plays-section" className="game-play-section-container">
      <Swiper
        loop={true}
        centeredSlides={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
        <SwiperSlide>
          <AHGamePlay1 />
        </SwiperSlide>
        <SwiperSlide>
          <AHGamePlay2 />
        </SwiperSlide>
        <SwiperSlide>
          <AHGamePlay3 />
        </SwiperSlide>
        <SwiperSlide>
          <AHGamePlay4 />
        </SwiperSlide>
      </Swiper>
    </Box>
  );
};

export default AHGamePlaySection;
