// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import SwiperProvider from "./SwiperContext";

// ** Swiper components import
import { Swiper, SwiperSlide } from "swiper/react";

// ** Swiper css import
import "swiper/css";

// ** Components import
import CharacterSlice from "../../components/character-slices/CharacterSlice";
import CharactersSlice from "../../components/character-slices";
import FeatureCard, { FeatureInfo } from "../../components/feature-card";

// ** Assets import
import NFTTradeBackground from "./images/nft-trade-bg.svg";

// ** Css import
import "./styles.css";
import { AHAssets } from "../../assets";

import { AH_CHARACTERS } from "../../components/character-slices";

const AH_FEATURES: FeatureInfo[] = [
  {
    title: "Earn & Own",
    description:
      "Lorem ipsum dolor sit amet consectetur. Rhoncus ultrices id lectus mattis augue. Aliquam lorem in",
  },
  {
    title: "Mixed reality",
    description:
      "Lorem ipsum dolor sit amet consectetur. Rhoncus ultrices id lectus mattis augue. Aliquam lorem in",
  },
  {
    title: "Free Legend",
    description:
      "Lorem ipsum dolor sit amet consectetur. Rhoncus ultrices id lectus mattis augue. Aliquam lorem in",
  },
];

const AHFeaturesSection = () => {
  return (
    <SwiperProvider>
      <Box className="ah-features-section-container">
        <Box className="ah-features-section-content">
          {/* Features Info */}
          <Box className="features-info-container">
            <Box className="features-title-box">
              <Typography className="features-title">
                Lorem ipsum dolor sit amet
              </Typography>
              <Typography component="div" className="features-description">
                Lorem ipsum dolor sit amet consectetur. Orci mauris at tempus
                sed enim tortor vitae lorem. Quam massa leo augue turpis turpis
                neque. Elit tincidunt
              </Typography>
            </Box>
            <Box className="features-card-container">
              {AH_FEATURES.map((feature) => (
                <FeatureCard
                  key={`ah-feature-${feature.title}`}
                  feature={feature}
                />
              ))}
            </Box>
            <Box className="features-section-download-link-container">
              <Typography className="download-title">Download on</Typography>
              <Box className="download-btn-box">
                <Box
                  component="button"
                  className="feature-section-download-btn-container orange-bg-shadow"
                >
                  <img
                    src={AHAssets.AHBtnLeftDecor}
                    className="btn-decor-img"
                  />
                  <img
                    src={AHAssets.AHGooglePlay}
                    className="feature-section-download-btn-icon"
                  />
                  <Typography className="feature-section-download-btn-text">
                    Google Play
                  </Typography>
                  <img
                    src={AHAssets.AHBtnRightDecor}
                    className="btn-decor-img"
                  />
                </Box>
                <Box
                  component="button"
                  className="feature-section-download-btn-container orange-bg-shadow"
                >
                  <img
                    src={AHAssets.AHBtnLeftDecor}
                    className="btn-decor-img"
                  />
                  <img
                    src={AHAssets.AHApple}
                    className="feature-section-download-btn-icon"
                  />
                  <Typography className="feature-section-download-btn-text">
                    App Store
                  </Typography>
                  <img
                    src={AHAssets.AHBtnRightDecor}
                    className="btn-decor-img"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          {/* Characters Info */}
          <Box className="character-info-container">
            <Box className="features-title-box">
              <Typography className="features-title">Characters</Typography>
              <Typography className="features-description">
                Lorem ipsum dolor sit amet consectetur. Orci mauris at tempus
                sed enim tortor vitae lorem. Quam massa leo augue turpis turpis
                neque. Elit tincidunt
              </Typography>
            </Box>
            <Box className="character-swiper-container">
              <CharactersSlice />
            </Box>
          </Box>
          {/* Archer Game Info */}
          <Box className="archer-hunter-info-container">
            <Box className="archer-info-box">
              <Typography className="features-title">
                What is Archer Hunter?
              </Typography>
              <Typography component="div" className="features-description">
                Lorem ipsum dolor sit amet consectetur. Sit consequat lorem
                mattis vitae quisque pellentesque sed. Velit dolor volutpat
                augue non elementum aliquam enim. Imperdiet amet fringilla
                feugiat nisi egestas penatibus aliquam adipiscing at. Nec at
                quis netus sem
              </Typography>
            </Box>
            <img src={AHAssets.AHHeroCard} className="ah-feature-hero-card" />
          </Box>
        </Box>
        {/* NFT Trade Info */}
        <Box className="ah-nft-trade-info-container">
          <Box className="nft-trade-info-box">
            <img src={AHAssets.AHNftCardCharacter1} className="nft-card-character-1-decor" />
            <img src={AHAssets.AHNftCardCharacter2} className="nft-card-character-2-decor" />
            <img src={NFTTradeBackground} className="nft-info-card-bg-img" />
            <Box className="nft-trade-content">
              <Typography className="nft-card-title">Trade NFTs</Typography>
              <Box className="nft-card-description-box">
                <Typography className="nft-card-description">
                  Lorem ipsum dolor sit amet consectetur. Orci mauris at tempus
                  sed enim tortor vitae lorem. Quam massa leo augue
                </Typography>
              </Box>
              <Box
                component="button"
                className="feature-section-play-now-btn-container orange-bg-shadow"
              >
                <img src={AHAssets.AHBtnLeftDecor} className="btn-decor-img" />

                <Typography className="feature-section-play-now-btn-text">
                  Play now
                </Typography>
                <img src={AHAssets.AHBtnRightDecor} className="btn-decor-img" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </SwiperProvider>
  );
};

export default AHFeaturesSection;
