// ** MUI import
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

// ** Assets import
import { AHAssets } from "../../assets";

// ** Css import
import "./styles.css";

const SOCIAL_LINKS = [
  {
    id: "linkedin-ref-link",
    url: "#",
    icon: AHAssets.AHLinkedInIcon,
  },
  {
    id: "discord-ref-link",
    url: "#",
    icon: AHAssets.AHDiscordIcon,
  },
  {
    id: "x-ref-link",
    url: "#",
    icon: AHAssets.AHXIcon,
  },
];

const SocialLinksBox = () => {
  return (
    <Box className="social-links-container">
      {SOCIAL_LINKS.map((link) => (
        <Box
          key={link.id}
          component={Link}
          href={link.url}
          className="social-link-box"
        >
          <img src={link.icon} className="social-link-icon" />
        </Box>
      ))}
    </Box>
  );
};

export default SocialLinksBox;
