// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import "./styles.css";

// ** Assets import
import { AHAssets } from "../../assets";
import AHBackedSectionTopSubtract from "./images/top-subtract.svg";
import AHBackedSectionBottomSubtract from "./images/bottom-subtract.svg";

const BACKED_LIST = [
  {
    id: "sky-mavis",
    logo: AHAssets.AHSkyMavisLogo,
    class: "sky-mavis-logo",
  },
  {
    id: "animoca",
    logo: AHAssets.AHAnimocaLogo,
    class: "animoca-logo",
  },
  {
    id: "spartan",
    logo: AHAssets.AHSpartanLogo,
    class: "spartan-logo",
  },
  {
    id: "okx-ventures",
    logo: AHAssets.AHOkxVenturesLogo,
    class: "okx-ventures-logo",
  },
  {
    id: "kahuna",
    logo: AHAssets.AHKahunaLogo,
    class: "kahuna-logo",
  },
];

const AHBackedBySection = () => {
  return (
    <Box className="backed-by-section-container">
      <img
        src={AHBackedSectionTopSubtract}
        className="backed-by-section-top-subtract"
      />
      <Typography className="backed-by-section-title">BACKED BY</Typography>
      <Box className="backed-by-list">
        {BACKED_LIST.map((brand) => (
          <img key={brand.id} src={brand.logo} className={brand.class} />
        ))}
      </Box>
      <img
        src={AHBackedSectionBottomSubtract}
        className="backed-by-section-bottom-subtract"
      />
    </Box>
  );
};

export default AHBackedBySection;
