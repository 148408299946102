// ** MUI import
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// ** Assets import
import { AHAssets } from "../../assets"

// ** Css import
import './styles.css'


const ConnectButton = () => {
    return (
        <Box component='button' className="connect-btn-container orange-bg-shadow">
            <img src={AHAssets.AHBtnLeftDecor} className="btn-decor-img"/>
            <Typography className="connect-btn-text">Connect Wallet</Typography>
            <img src={AHAssets.AHBtnRightDecor} className="btn-decor-img"/>
        </Box>
    )
}

export default ConnectButton