// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Components import
import GamePlayRow from "./GamePlayRow";

// ** Type import
import { GamePlayInfo } from "./GamePlayRow";

// ** Assets import
import { AHAssets } from "../../assets";

// ** Css import
import "./styles.css";

const GAME_PLAYS: GamePlayInfo[] = [
  {
    id: "game-play-1",
    label: "Gameplay 1",
  },
  {
    id: "game-play-2",
    label: "Gameplay 2",
  },
  {
    id: "game-play-3",
    label: "Gameplay 3",
    description:
      "Lorem ipsum dolor sit amet consectetur. Orci mauris at tempus sed enim tortor vitae lorem. Quam massa leo augue turpis turpis neque. Elit tincidunt suspendisse hendrerit porttitor. Viverra ultrices pretium velit phasellus felis convallis rutrum nec.",
    icon: AHAssets.AHPointer,
  },
  {
    id: "game-play-4",
    label: "Gameplay 4",
  },
];

const AHGamePlay3 = () => {
  return (
    <Box className="ah-game-play3-container">
      <Box className="ah-game-play-info-container">
        {GAME_PLAYS.map((gamePlay) => (
          <GamePlayRow key={gamePlay.id} info={gamePlay} />
        ))}
        <Box className="ah-game-play-action-box">
          <Box
            component="button"
            className="play-now-btn-container orange-bg-shadow"
          >
            <img src={AHAssets.AHBtnLeftDecor} className="btn-decor-img" />
            <Typography className="play-now-btn-text">Play Now</Typography>
            <img src={AHAssets.AHBtnRightDecor} className="btn-decor-img" />
          </Box>
        </Box>
        <Box className="ah-game-play-overlay-box" />
      </Box>
      <Box className="ah-game-play-img-container">
        <img src={AHAssets.AHGamePlay} className="ah-game-play-img" />
      </Box>
    </Box>
  );
};

export default AHGamePlay3;
