// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export interface GamePlayInfo {
  id: string;
  label: string;
  description?: string;
  icon?: string;
}

type GamePlayRowProps = {
  info: GamePlayInfo;
};

const GamePlayRow = ({ info }: GamePlayRowProps) => {
  return (
    <Box className="game-play-row-container">
      <Box className="game-play-title-container">
        {info.icon ? (
          <img src={info.icon} className="pointer-img" />
        ) : (
          <Box className="hold-place-box" />
        )}
        <Typography className="ah-game-play-title">{info.label}</Typography>
      </Box>
      {info.description && (
        <Box className="ah-game-play-description-box">
          <Typography className="ah-game-play-description">
            {info.description}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default GamePlayRow;
