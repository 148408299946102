// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// ** Assert import
import { AHAssets } from "../../assets";

import BgImage from "./images/character-slice-bg.svg";

// ** Css import
import "./styles.css";

export interface CharacterInfo {
  name: string;
  image: string;
  description: string;
  prevImage?: string;
  nextImage?: string;
}

type CharacterSliceProps = {
  info: CharacterInfo;
};

const CharacterSlice = ({ info }: CharacterSliceProps) => {
  return (
    <Box className="character-info-slice">
      <img src={BgImage} className="character-bg-img" />
      <Box className="character-info-box">
        <Typography className="character-name">{info.name}</Typography>
        <Box className="character-description-box">
          <Typography component="p" className="character-description">
            {info.description}
          </Typography>
        </Box>
        <Box
          component="button"
          className="learn-more-btn-container orange-bg-shadow"
        >
          <img src={AHAssets.AHBtnLeftDecor} className="btn-decor-img" />
          <Typography className="learn-more-btn-text">Learn More</Typography>
          <img src={AHAssets.AHBtnRightDecor} className="btn-decor-img" />
        </Box>
      </Box>
      <img src={info.image} className="character-image" />
    </Box>
  );
};

export default CharacterSlice;
