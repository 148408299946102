// ** MUI import
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

// ** Assets import
import { AHAssets } from "../../assets";

// ** Css import
import "./styles.css";

const SOCIAL_LINKS = [
  {
    id: "ah-linkedin-ref",
    url: "#",
    icon: AHAssets.AHLinkedinSquareIcon,
  },
  {
    id: "ah-discord-ref",
    url: "#",
    icon: AHAssets.AHDiscordSquareIcon,
  },
  {
    id: "ah-x-ref",
    url: "#",
    icon: AHAssets.AHXSquareIcon,
  },
  {
    id: "ah-docs-ref",
    url: "#",
    icon: AHAssets.AHDocSquareIcon,
  },
];

const AHFooter = () => {
  return (
    <Box className="ah-footer-container">
      <Box className="ah-footer-info-box">
        <Typography className="ah-footer-title">Stay updated</Typography>
        <Typography className="ah-footer-description">
          Lorem ipsum dolor sit amet consectetur. Orci mauris at tempus sed enim
          tortor vitae lorem. Quam massa leo augue turpis turpis neque. Elit
          tincidunt
        </Typography>
      </Box>
      <Box className="social-links-box">
        {SOCIAL_LINKS.map((link) => (
            <Box
              key={link.id}
              component={Link}
              href={link.url}
              target="_blank"
              className="social-link"
            >
              <img  key={link.id} src={link.icon} className="footer-social-link-icon" />
            </Box>
        ))}
      </Box>
    </Box>
  );
};

export default AHFooter;
