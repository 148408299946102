// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Components import
import HeroesSlice from "../../components/hero-slices";
import GameTrailer from "../../components/game-trailer";
import SocialLinksBox from "../../components/social-links-box";

// ** Assets import
import { AHAssets } from "../../assets";

// ** Css import
import "./styles.css";

const AHHeroSection = () => {
  return (
    <Box className="hero-section-container">
      {/* section text info */}
      <Box className="hero-section-left-side-content">
        <Box className="hero-section-left-side-info">
          <Box>
            <Typography className="hero-section-main-title fadeInUp-animation">
              Master the Bow
            </Typography>
            <Typography className="hero-section-main-title fadeInUp-animation">
              Conquer the World
            </Typography>
          </Box>
          <Typography className="hero-section-sub-title fadeInUp-animation">
            Lorem ipsum dolor sit amet consectetur. Orci mauris at tempus sed
            enim tortor vitae lorem. Quam massa leo augue turpis turpis neque.
            Elit tincidunt
          </Typography>
          <Box
            component="button"
            className="pre-register-btn-container orange-bg-shadow fadeInUp-animation"
          >
            <img src={AHAssets.AHBtnLeftDecor} className="btn-decor-img" />
            <Typography className="pre-register-btn-text">
              Pre-Register
            </Typography>
            <img src={AHAssets.AHBtnRightDecor} className="btn-decor-img " />
          </Box>
        </Box>
        <Box className="hero-section-download-link-container fadeInUp-animation">
          <Typography className="download-link-title">Available on</Typography>
          <Box className="download-link-container">
            <Box className="download-link-btn">
              <img
                src={AHAssets.AHGooglePlay}
                className="download-link-btn-icon"
              />
              <Typography className="download-link-btn-text">
                Google play
              </Typography>
            </Box>
            <Box className="download-link-btn">
              <img src={AHAssets.AHApple} className="download-link-btn-icon" />
              <Typography className="download-link-btn-text">
                App Store
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* section image info */}
      <HeroesSlice />
      {/* hero section trailer game */}
      <Box className="hero-section-trailer-container fadeInUp-animation">
        <GameTrailer />
        <SocialLinksBox />
      </Box>
    </Box>
  );
};

export default AHHeroSection;
