// ** MUI import
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// ** Assets import
import { AHAssets } from "../../assets"

// ** Css import
import "./styles.css"

const LoginButton = () => {
    return (
        <Box component='button' className="login-btn-container blue-bg-shadow">
            <img src={AHAssets.AHBtnLeftDecor} className="btn-decor-img" />
            <Typography className="login-btn-text">Log in</Typography>
            <img src={AHAssets.AHBtnRightDecor} className="btn-decor-img" />
        </Box>
    )
}

export default LoginButton