// ** React import
import { useState } from "react";

// ** MUI import
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";

// ** Components import
import LoginButton from "../../components/login-btn";
import ConnectButton from "../../components/connect-btn";

// ** Asset import
import { AHAssets } from "../../assets";

import { AppBarItems } from ".";

const AHAppBarMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleOpenAppBar = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseAppBar = () => {
    setAnchorEl(null);
  };

  return (
    <Box className="ah-app-bar-menu-container">
      <Box
        component="button"
        id="ah-app-mobile-btn"
        className="ah-mobile-app-bar-btn ah-white-gradient-bg-box"
        aria-controls={open ? "ah-mobile-app-bar-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleOpenAppBar}
      >
        <img src={AHAssets.AHHamburger} className="ah-app-bar-menu-icon" />
      </Box>
      <Menu
        id="ah-mobile-app-bar-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseAppBar}
        slotProps={{
          paper: {
            style: {
              background: "transparent",
              boxShadow: "none",
            },
          },
        }}
        MenuListProps={{
          "aria-labelledby": "ah-app-mobile-btn",
        }}
      >
        <Box className="mobile-app-bar-container">
          {AppBarItems.map((item) => (
            <Box className="ah-app-bar-item" key={item.id}>
              <Typography className="ah-app-bar-label">{item.label}</Typography>
            </Box>
          ))}
          <LoginButton />
          <ConnectButton />
        </Box>
      </Menu>
    </Box>
  );
};

export default AHAppBarMenu;
