// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Assets import
import AHFeatureCardWineTop from "./images/vine-top.svg";
import AHFeatureCardWineBottom from "./images/vine-bottom.svg";

// Css import
import "./styles.css";

export interface FeatureInfo {
  title: string;
  description: string;
}

export interface FeatureCardProps {
  feature: FeatureInfo;
}

const FeatureCard = ({ feature }: FeatureCardProps) => {
  return (
    <Box className="feature-card-container">
      <img src={AHFeatureCardWineTop} className="feature-card-top-vine" />
      <Box className="feature-content-box">
        <Typography className="feature-card-title">
            {feature.title}
        </Typography>
        <Typography className="feature-card-description">
            {feature.description}
        </Typography>
      </Box>
      <img src={AHFeatureCardWineBottom} className="feature-card-bottom-vine" />
    </Box>
  );
};

export default FeatureCard;
