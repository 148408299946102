// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Assets import
import { AHAssets } from "../../assets";
import SocialGameSectionTopSubtract from "./image/social-game-top-subtract.svg";
import SocialGameSectionBottomSubtract from "./image/social-game-bottom-subtract.svg";

// ** Css import
import "./styles.css";

const AHGameSocialSection = () => {
  return (
    <Box className="ah-game-social-section-container">
      <img
        src={SocialGameSectionTopSubtract}
        className="game-social-section-top-subtract"
      />
      <img
        src={AHAssets.AHAvatar1}
        className="ah-game-social-section-avatar-1"
      />
      <img
        src={AHAssets.AHAvatar2}
        className="ah-game-social-section-avatar-2"
      />
      <img
        src={AHAssets.AHAvatar3}
        className="ah-game-social-section-avatar-3"
      />
      <Box className="ah-game-social-section-info-container">
        <Typography className="game-social-section-title">
          Gamesocial
        </Typography>
        <Typography component="div" className="game-social-section-description">
          Lorem ipsum dolor sit amet consectetur. Orci mauris at tempus sed enim
          tortor vitae lorem. Quam massa leo augue
        </Typography>
        <Box
          component="button"
          className="join-discord-btn-container orange-bg-shadow"
        >
          <img src={AHAssets.AHBtnLeftDecor} className="btn-decor-img" />
          <Typography className="join-discord-btn-text">
            Join Our Discord
          </Typography>
          <img src={AHAssets.AHBtnRightDecor} className="btn-decor-img" />
        </Box>
      </Box>
      <img
        src={AHAssets.AHAvatar4}
        className="ah-game-social-section-avatar-4"
      />
      <img
        src={AHAssets.AHAvatar5}
        className="ah-game-social-section-avatar-5"
      />
      <img
        src={AHAssets.AHAvatar6}
        className="ah-game-social-section-avatar-6"
      />
      <img
        src={SocialGameSectionBottomSubtract}
        className="game-social-section-bottom-subtract"
      />
    </Box>
  );
};

export default AHGameSocialSection;
