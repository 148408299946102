// ** react import
import { Fragment } from "react";

// ** MUI Import
import Box from "@mui/material/Box";

// ** Hooks import
import { useSwiperContext } from "../../section/features-section/SwiperContext";

// ** Swiper components import
import { Swiper, SwiperSlide } from "swiper/react";

// ** Swiper css import
import "swiper/css";

// ** Slice import
import CharacterSlice, { CharacterInfo } from "./CharacterSlice";

// Css import
import "./styles.css";

// ** Assets import
import { AHAssets } from "../../assets";

export const AH_CHARACTERS: CharacterInfo[] = [
  {
    name: "Character 1",
    description:
      "Lorem ipsum dolor sit amet consectetur. Eget elementum vitae in turpis. Id eu urna consectetur nec nunc pharetra fermentum ac massa.",
    image: AHAssets.AHCharacter1,
    prevImage: "",
  },
  {
    name: "Character 2",
    description:
      "Lorem ipsum dolor sit amet consectetur. Eget elementum vitae in turpis. Id eu urna consectetur nec nunc pharetra fermentum ac massa.",
    image: AHAssets.AHCharacter2,
  },
  {
    name: "Character 3",
    description:
      "Lorem ipsum dolor sit amet consectetur. Eget elementum vitae in turpis. Id eu urna consectetur nec nunc pharetra fermentum ac massa.",
    image: AHAssets.AHCharacter3,
  },
];

const CharactersSlice = () => {
  const { setSwiper, swiper } = useSwiperContext();

  const handlePrevSlice = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  const handleNextSlice = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };
  return (
    <Fragment>
      <Swiper onSwiper={setSwiper} onBeforeDestroy={() => setSwiper(null)}>
        {AH_CHARACTERS.map((character) => (
          <SwiperSlide key={`ah-character-${character.name}`}>
            <CharacterSlice info={character} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Box
        component="div"
        className="navigate-slice-prev-btn"
        onClick={handlePrevSlice}
      >
        <img src={AHAssets.AHPointer} className="prev-pointer-img" />
      </Box>
      <Box
        component="div"
        className="navigate-slice-next-btn"
        onClick={handleNextSlice}
      >
        <img src={AHAssets.AHPointer} className="next-pointer-img" />
      </Box>
    </Fragment>
  );
};

export default CharactersSlice;
