// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Asset import
import { AHAssets } from "../../assets";
import ArcherInfoTopSubtract from "./images/game-info-top-subtract.svg";
import ArcherInfoBottomSubtract from "./images/game-info-bottom-subtract.svg";

// ** Css import
import "./styles.css";

const AHGameInfoSection = () => {
  return (
    <Box className="archer-game-info-container">
      <img src={ArcherInfoTopSubtract} className="archer-info-top-subtract" />
      <img
        src={AHAssets.AHGameInfoCharacter}
        className="archer-game-info-character-img"
      />
      <Box className="archer-game-info-overlay-box" />
      <Box className="archer-game-info-box">
        <Typography className="archer-game-info-title">
          What is Archer Hunter?
        </Typography>
        <Typography className="archer-game-info-description">
          Lorem ipsum dolor sit amet consectetur. Orci mauris at tempus sed enim
          tortor vitae lorem. Quam massa leo augue turpis turpis neque. Elit
          tincidunt
        </Typography>
      </Box>
      <img
        src={ArcherInfoBottomSubtract}
        className="archer-info-bottom-subtract"
      />
    </Box>
  );
};

export default AHGameInfoSection;
