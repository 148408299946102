// ** MUI import
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";

// ** Components import
import AHAppBarMenu from "./AHAppbarMenu";
import LoginButton from "../../components/login-btn";
import ConnectButton from "../../components/connect-btn";

// ** Assets import
import { AHAssets } from "../../assets";

// ** Css import
import "./styles.css";

export const AppBarItems = [
  {
    id: "ah-home",
    label: "Home",
  },
  {
    id: "ah-new",
    label: "New",
  },
  {
    id: "ah-gallery",
    label: "Gallery",
  },
  {
    id: "ah-download",
    label: "Download",
  },
];

const AHAppBar = () => {
  return (
    <AppBar className="ah-app-bar" elevation={0}>
      <Box className="ah-app-bar-content">
        <img src={AHAssets.AHLogo} className="ah-app-logo" />
        <Box className="ah-app-bar-items-container">
          {AppBarItems.map((item) => (
            <Box className="ah-app-bar-item" key={item.id}>
              <Typography className="ah-app-bar-label">{item.label}</Typography>
            </Box>
          ))}
        </Box>
        <Box className="ah-app-bar-action-container">
          <LoginButton />
          <ConnectButton />
        </Box>
        <AHAppBarMenu />
      </Box>
    </AppBar>
  );
};

export default AHAppBar;
