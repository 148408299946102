// ** MUI import
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// ** Assets import
import { AHAssets } from "../../assets";

// ** Css import
import "./styles.css";

const GameTrailer = () => {
  return (
    <Box className="game-trailer-container">
      <Box className="game-trailer-box">
        <img src={AHAssets.AHTrailerGame} className="trailer-game-img" />
        <Box className="watch-game-trailer">
          <img src={AHAssets.AHPlay} className="trailer-game-play-icon" />
          <Typography className="watch-trailer-text">Watch trailer</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default GameTrailer;
