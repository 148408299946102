import AHLogo from "./ah/logo.svg";
import AHPlay from "./ah/play.svg";
import AHHero1 from "./ah/hero1.svg";
import AHHero2 from "./ah/hero2.svg";
import AHHero3 from "./ah/hero3.svg";
import AHHero4 from "./ah/hero4.svg";
import AHHero5 from "./ah/hero5.svg";
import AHApple from "./ah/apple.svg";
import AHXIcon from "./ah/x-icon.svg";
import AHGooglePlay from "./ah/google-play.svg";
import AHTrailerGame from "./ah/trailer-game.svg";
import AHDiscordIcon from "./ah/discord-icon.svg";
import AHLinkedInIcon from "./ah/linkedin-icon.svg";
import AHBtnLeftDecor from "./ah/btn-left-decor.svg";
import AHBtnRightDecor from "./ah/btn-right-decor.svg";
import AHAnimocaLogo from './ah/animoca-logo.svg';
import AHSpartanLogo from "./ah/spartan-logo.svg";
import AHSkyMavisLogo from './ah/sky-mavis-logo.svg';
import AHOkxVenturesLogo from "./ah/okx-ventures.svg";
import AHKahunaLogo from './ah/kahuna-logo.svg';
import AHPointer from "./ah/pointer.svg";
import AHGamePlay from "./ah/game-play-image.svg"
import AHAvatar1 from "./ah/avatar1.svg";
import AHAvatar2 from "./ah/avatar2.svg";
import AHAvatar3 from "./ah/avatar3.svg";
import AHAvatar4 from "./ah/avatar4.svg";
import AHAvatar5 from "./ah/avatar5.svg";
import AHAvatar6 from "./ah/avatar6.svg";
import AHCharacter1 from "./ah/character1.svg";
import AHCharacter2 from "./ah/character2.svg";
import AHCharacter3 from './ah/character3.svg';
import AHHeroCard from "./ah/hero-card.svg";
import AHGameInfoCharacter from "./ah/archer-game-info-character.svg"
import AHLinkedinSquareIcon from "./ah/linkedin-square-icon.svg";
import AHDiscordSquareIcon from "./ah/discord-square-icon.svg";
import AHXSquareIcon from "./ah/x-square-icon.svg";
import AHDocSquareIcon from "./ah/docs-square-icon.svg";
import AHHamburger from "./ah/hamburger.svg";
import AHNftCardCharacter1 from "./ah/nft-card-character-1.svg";
import AHNftCardCharacter2 from "./ah/nft-card-character-2.svg";

const AHAssets = {
    AHLogo,
    AHPlay,
    AHHero1,
    AHHero2,
    AHHero3,
    AHHero4,
    AHHero5,
    AHXIcon,
    AHApple,
    AHAvatar1,
    AHAvatar2,
    AHAvatar3,
    AHAvatar4,
    AHAvatar5,
    AHAvatar6,
    AHPointer,
    AHHeroCard,
    AHGamePlay,
    AHHamburger,
    AHCharacter1,
    AHCharacter2,
    AHCharacter3,
    AHGooglePlay,
    AHKahunaLogo,
    AHAnimocaLogo,
    AHSpartanLogo,
    AHSkyMavisLogo,
    AHDiscordIcon,
    AHXSquareIcon,
    AHTrailerGame,
    AHLinkedInIcon,
    AHBtnLeftDecor,
    AHDocSquareIcon,
    AHBtnRightDecor,
    AHOkxVenturesLogo,
    AHGameInfoCharacter,
    AHNftCardCharacter1,
    AHNftCardCharacter2,
    AHDiscordSquareIcon,
    AHLinkedinSquareIcon,
}

export { AHAssets }