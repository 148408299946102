// ** React import
import {
  useMemo,
  useState,
  ReactNode,
  useContext,
  useCallback,
  createContext,
} from "react";

import type SwiperCore from "swiper";

interface ISwiperContext {
  swiper: SwiperCore | null;
  setSwiper: (swiper: SwiperCore | null) => void;
  destroySwiper: () => void;
}

const SwiperContext = createContext<ISwiperContext | undefined>(undefined);

export const useSwiperContext = () => {
  const context = useContext(SwiperContext);
  if (!context) {
    throw new Error("useSwiperContext must be used within a SwiperProvider");
  }

  return context;
};

const SwiperProvider = ({ children }: { children: ReactNode }) => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);

  const destroySwiper = useCallback(() => {
    if (swiper) {
      swiper.destroy();
      setSwiper(null);
    }
  }, [swiper]);

  const contextValue = useMemo(() => {
    return {
      swiper,
      setSwiper,
      destroySwiper,
    };
  }, [swiper, destroySwiper]);

  return (
    <SwiperContext.Provider value={contextValue}>
      {children}
    </SwiperContext.Provider>
  );
};

export default SwiperProvider;
